
import { PageBase } from '@/core/models/shared';
import { BoletoService} from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelBoletoSafra extends PageBase { 
    service = new BoletoService();
    html:string ="";

    mounted() {
        const filter:any = this.$route.query;

        this.service.VisualizarBoletoSafra(filter.codigoZoop)
            .then(
                res => {
                    this.html = res.data;
                }, 
                err => {
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            );  
    }
}
